/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.react-datepicker-wrapper {
    display: block;
}

.rental-object,
.admin-user,
.customer {
    border-color: #111111;
    border: 1px solid;
    border-radius: 0.25rem;
    margin: 20px 0;
    padding: 10px;
}

.rental-object-status
{
    background-color: #DDDDDD;
    border-color: #111111;
    border: 1px solid;
    border-radius: 0.25rem;
    padding: 10px;
    margin: 10px 0 0 0;
}

.admin-background {
    background-color: #DDD;
}

.left-radius {
    border-radius: 0.25rem 0 0 0.25rem;
}

.right-radius {
    border-radius: 0 0.25rem 0.25rem 0;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.text-center {
    text-align: center;
}

.font-bold {
    font-weight: bold;
}

.vertical-space {
    display: block;
    margin-bottom: 40px;
}

.overflow-hidden {
    overflow: hidden;
}

.margin-top-bottom {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-top {
    margin-top: 10px;
}

.margin-bottom {
    margin-bottom: 10px;
}

.margin-bottom-large {
    margin-bottom: 50px;
}

.margin-bottom-medium {
    margin-bottom: 40px;
}


.underline {
    
}

.nav-item.active {
    color: black !important;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
}

.nav-link.text-white {
    color: white !important;
}

.nav-link.text-black {
    color: black !important;
}

.nav-item {
    padding: 0.25rem 0;
}

.nav-link {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.horisontal-line {
    margin-top: 20px;
    border-bottom: 2px solid #5a6268;
    margin-bottom: 20px;
}

a {
    color: black;
}

.navbar > *, ::before, ::after {
    box-sizing: content-box !important;
}

.href-link {
    color: #0075BC;
    filter: brightness(1);
}

.href-link:hover {
    filter: brightness(0.6);
}